import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { COMMON_SETTINGS } from '@app-shared/settings/common.settings';
import { EncodeDecodeTokenService } from '@app-shared/services/encode-decode-token-service/encode-decode-token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private tokenService: EncodeDecodeTokenService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = this.tokenService.getToken('token');

        let headers: any = {
            "content-language": "en",
            "Content-Type": "application/json"
        };

        // if request is from posthog events API
        if (request.url.includes('https://app.posthog.com')) {
            headers = {
                "content-language": "en",
                'Content-Type': 'application/json' // Set content type to x-www-form-urlencoded
            }

            request = request.clone({ setHeaders: headers });
        } 
        
        else if (!!token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${token}`, 
                    client_id: COMMON_SETTINGS.ClientId.Sandbox,
                    env: COMMON_SETTINGS.HostEnvironment.Sandbox  
                }
            });
        }

        return next.handle(request);
    }
}