import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from '@app-shared/components/header/header.component';

const rootRouterConfig: Routes = [
  {
    path: '',
    component: HeaderComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./views/home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
  /* CUSTOMER AUTHENTICATION */
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./views/auth/customer/customer.module').then(
            (m) => m.CustomersModule
          ),
      },
    ],
  },
  /* CUSTOMER PANEL - DASHBOARD */
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./views/customer-panel/customer-panel.module').then(
            (m) => m.CustomerPanelModule
          ),
      },
    ],
  },

  {
    path: '',
    component: HeaderComponent,
    children: [
      {
        path: 'error',
        loadChildren: () =>
          import('./views/error-page/error-page.module').then(
            (m) => m.ErrorPageModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

const routerOptions: any = {
  useHash: false,
  //anchorScrolling: 'false',
};

@NgModule({
  imports: [RouterModule.forRoot(rootRouterConfig, routerOptions)],
  exports: [RouterModule],
  /*providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],*/
})
export class AppRoutingModule {}
