import { Injectable } from '@angular/core';
import posthog from 'posthog-js';
import { COMMON_SETTINGS } from '@app-shared/settings/common.settings';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IfSandboxNotLocal,
  IfProd,
} from '@app-shared/general-functions/general-functions';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  public currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe({
      next: (_messaging: any) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      },
    });
  }

  public requestPermission() {
    console.log('REQUESTING FIREBASE TOKEN');

    this.angularFireMessaging.requestToken.subscribe({
      next: (token) => {
        //console.log('Permission granted! Save to the server!', token);
      },
      error: (error) => {
        console.error('Unable to get permission to notify.', error);
      },
    });
  }

  public receiveMessaging() {
    this.angularFireMessaging.messages.subscribe({
      next: (payload: any) => {
        console.log('New Message received', payload);
        this.currentMessage.next(payload);
      },
    });
  }
}
