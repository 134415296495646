<div class="navbar flex flex-wrap">
  <!-- navbar -->
  <nav class="flex justify-between bg-header text-white w-screen">
    <div class="px-3 lg:px-8 pb-3 pt-4 flex w-full items-center">
      <a class="text-3xl font-bold font-heading mx-auto">
        <img class="img-brand" src="./assets/images/placeholder/switchboard-logo-quick-event-purple.svg" alt="Switchboard.live"> 
      </a>

      <!--
      <a class="switchboard-text ml-auto mt-3 pr-3">
        A Switchboard Live Experience
      </a>-->
    </div>
  </nav>
</div>

<div class="main">
  <router-outlet></router-outlet>
</div>

<div class="footer">
  <div class="text-center pt-3 pb-3">
    <img class="h-3rem mt-3" src="assets/images/placeholder/switchboard-logo-quick-event-purple.svg" alt="Switchboard.live"> 
    <p class="mt-5">© 2024 Switchboard Live</p>
    <!--
      
      <p class="text-white">Follow us on:</p>
    -->
    
    <div class="flex justify-content-center vertical-align-middle mt-5 mb-6">
      <a href="https://twitter.com/switchboardlive" target="_blank">
        <img src="assets/images/placeholder/gray-social/twitter-gray.png">
      </a>

      
      

      <a href="https://www.facebook.com/switchboard.live/" target="_blank">
        <img src="assets/images/placeholder/gray-social/facebook-gray.png">
      </a>
      
      <a href="https://www.youtube.com/@SwitchboardLive" target="_blank">
        <img src="assets/images/placeholder/gray-social/youtube-gray.png">  
      </a>
      
      <a href="https://www.linkedin.com/company/switchboardlive/" target="_blank">
        <img src="assets/images/placeholder/gray-social/linkedin-gray.png">
      </a>
      
      <a href="https://www.instagram.com/switchboard.live/" target="_blank">
        <img src="assets/images/placeholder/gray-social/instagram-gray.png">
      </a>

      <!--
      <a>
        <img src="assets/images/placeholder/gray-social/tiktok-gray.png">
      </a>-->
    </div>
  </div>

  
</div>