import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  OnChanges,
  OnInit,
  OnDestroy,
  HostListener
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from '@angular/router';
import { Subscription } from 'rxjs';
import { PosthogService } from '@app-shared/services/logging-service/posthog.service';
import { FirebaseService } from '@app-shared/services/firebase-service/firebase.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'my-pet-go';
  private req: Subscription;
  private location: string = '';
  private screenSize: number = 1600;
  public message: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private postHog: PosthogService,
    private firebaseService: FirebaseService
  ) {
    this.location = this.router.url;
    this.req = this.router.events.subscribe((event: any) => {
      if(event){

        if(router.url !== this.location){
          this.location = router.url;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          
        }
      }
    });

    /*this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };*/
  }

  ngOnInit(): void {
    // Initialize Posthog
    this.postHog.initializePosthog();

    // initialize firebase
    if(window?.location?.host?.match('quickevents.switchboard.live') 
      || window?.location?.host?.match('sandbox.quick.switchboard.live')){
      this.GetFirebase();
    }
  }

  public GetFirebase() {
    this.firebaseService.requestPermission();
    this.firebaseService.receiveMessaging();
    this.message = this.firebaseService.currentMessage;
  }
}
