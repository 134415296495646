import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncodeDecodeTokenService {
  private personalKey = environment?.personalAuthKey;

  constructor() {}

  public encodeToken(token: string): string {
    try {
      return CryptoJS.AES.encrypt(token, this.personalKey).toString();
    } catch (error) {
      console.error('Error encoding token', error);
      return this.handleErrorAuthorize('Invalid Token', error);
    }
  }

  public decodeToken(encodedToken: string): string {
    try {
      const bytes = CryptoJS.AES.decrypt(encodedToken, this.personalKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Error decoding token', error);
      return this.handleErrorAuthorize('Invalid Token', error);
    }
  }

  public handleErrorAuthorize(error: any, caught: any): any {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem(
      'loginError',
      'Your access has expired. Please login again to continue.'
    );
    window.location.href = '/account/login';
    throw error;
  }

  
  public saveToken(token: string, tokenName: string): void {
    const encodedToken = this.encodeToken(token);
    localStorage.setItem(tokenName, encodedToken);
  }

  public getToken(tokenName: string): string | null {
    const encodedToken = localStorage.getItem(tokenName);
    if (encodedToken) {
      return this.decodeToken(encodedToken);
    }
    return null;
  }
}
