import { Injectable } from '@angular/core';
import posthog from 'posthog-js';
import { COMMON_SETTINGS } from '@app-shared/settings/common.settings';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IfSandboxNotLocal,
  IfProd,
} from '@app-shared/general-functions/general-functions';

@Injectable({
  providedIn: 'root',
})
export class PosthogService {
  public host = {
    Local: 'Local',
    Sandbox: 'Sandbox',
    Production: 'Production',
  };
  public selectedEnv: string = this.host?.Sandbox;

  constructor() {}

  public initializePosthog() {
    if (!!IfSandboxNotLocal()) {
      const options = {
        api_host: 'https://app.posthog.com',
        capture_pageview: false,
        capture_pageleave: false,
        autocapture: false,
      };

      posthog.init('phc_d89npnaR3cyLwcGYuJhVxIOWkLJBO02vMFnQRbsl2RG', options);
    } else if (IfProd() === 'Production') {
      const options = {
        api_host: 'https://app.posthog.com',
        capture_pageview: false,
        capture_pageleave: false,
        autocapture: false,
      };

      posthog.init('phc_GCHZMxrHtMBybDyLrIIuvtXbNb676lgvZcctZGEiELJ', options);
    } else return;
  }

  public trackEvent(eventName: string, properties: any = {}) {
    if (!!IfSandboxNotLocal() || IfProd() === 'Production') {
      console.debug('EVENT TRACKED: ', {
        eventName: eventName,
        properties: properties,
      });
      posthog.capture(eventName, properties);
    }
  }

  public identifyUser(identity: any) {
    if (!!IfSandboxNotLocal() || IfProd() === 'Production') {
      posthog.register_once(identity); // sets once
      posthog.register(identity); // sends extra in every request
      posthog.identify(identity.email, identity);
    } else return;
  }

  // Leave Feedback
  public leaveFeedbackSurvey(): Observable<any[]> {
    return new Observable((observer) => {
      posthog.getActiveMatchingSurveys((surveys) => {
        const filteredSurveys = surveys.filter(
          (survey) => survey.type === 'api'
        );
        observer.next(filteredSurveys);
        observer.complete();
      }, true);
    });
  }

  public surveyShownDismissed(id: any, dismissed?: boolean) {
    if (!dismissed) posthog.capture('survey shown', { $survey_id: id });
    else posthog.capture('survey dismissed', { $survey_id: id });
  }

  // Capture Feedback
  public saveSurveyResponse(response) {
    posthog.capture('survey sent', response);
    console.debug('Survey Sent', response);
  }

  public checkHost() {
    if (location.hostname === COMMON_SETTINGS.Hostname.Local) {
      return 'Local';
    } else if (location.hostname === COMMON_SETTINGS.Hostname.Sandbox) {
      return 'Sandbox';
    } else {
      return 'Production';
    }
  }
}
