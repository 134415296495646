export const environment = {
  production: false,
  //server: 'http://localhost:4500',
  server: 'https://ssr-back.herokuapp.com',
  main_product_id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcxNTE3OTU2MzQzNDk=',
  google_recaptcha_key: '6Ld8Q68pAAAAAIKO9j_DMekQ95AqnUGcSl25ltge',
  firebase: {
    apiKey: "AIzaSyAAikMU4lTU3FzOEhxKGjOSoytpkPH_hF0",
    authDomain: "sandbox-switchboard-firebase.firebaseapp.com",
    projectId: "sandbox-switchboard-firebase",
    storageBucket: "sandbox-switchboard-firebase.appspot.com",
    messagingSenderId: "356613112636",
    appId: "1:356613112636:web:b97243cda4a74df61a9439",
    measurementId: "G-EBZ5SSDGN5"
  },
  personalAuthKey: 'z3zaSyA3ikMU4lzU3FzOEhxKHjO45oytpkPH_1gd'
};
