export const COMMON_SETTINGS = {
  AppName: 'QuickEvents',

  ClientId: {
    Google: 'fd9f701d6881757990736ed3fc',
    Sandbox: '17f7d924997b340a272b1cd1ab9',
    Production: 'b0fd6f6474b957824d66dc440',
  },

  ApiBaseUrl: {
    Sandbox: 'https://sandbox-cloud.switchboard.live',
    LocalHost: 'https://sandbox-cloud.switchboard.live',
    Production: 'https://cloud.switchboard.live',
  },

  AccountApiBaseUrl: {
    Sandbox: 'https://sandbox-accounts.switchboard.live',
    Production: 'https://accounts.switchboard.live',
    LocalHost: 'https://sandbox-accounts.switchboard.live',
  },

  SocialRedirectUriHost: {
    Local: 'http://localhost:8080',
    Sandbox: 'https://sandbox.quick.switchboard.live',
    Production: 'https://quickevents.switchboard.live',
  },

  Hostname: {
    Local: 'localhost:8080',
    Sandbox: 'sandbox.quick.switchboard.live',
    Production: 'quickevents.switchboard.live',
  },

  ApiVersion: '1.0',

  LocalStorageKeys: {
    AccessToken: 'access_token',
    RefreshToken: 'refresh_token',
    CustomerId: 'customer_id',
    CustomerFriendlyId: 'customer_friendly_id',
    CurrentStreamId: 'current_stream_id',
    CommixUserId: 'commix_user_id',
    StreamId: 'stream_id',
    SignUpChecker: 'signup_check',
  },
  PullLimit: 1000000,

  HostEnvironment: {
    Sandbox: 'sandbox',
    Production: 'cloud',
  },

  AppHostUrl: {
    Netlify: 'https://switchboard-mobile.netlify.app',
    Sandbox: 'https://sandbox.commix.live',
    Production: 'https://app.commix.live',
  },
  SocialLoginAppSettings: {
    Facebook: {
      ClientId: '943959486259826',
    },
    Google: {
      ClientId:
        '522819666433-5ianqf941ftdsbjdsnqppr7g2dm6tj48.apps.googleusercontent.com',
      ClientIdProd: '12025957541-mimspo5h8ocdmq3o3uegc7v1kcsj50m8.apps.googleusercontent.com'
    },
    Twitch: {
      ClientId: 'tmwbmbp83ic2kkg2dywnyot1n6aom0',
    },
  },
  StreamUrls: {
    Sandbox: 'rtmp://sandbox.stream.commix.live/live',
    Production: 'rtmp://stream.commix.live/live',
  },
};
