import { COMMON_SETTINGS } from '@app-shared/settings/common.settings';

export function ValidateEmail(input: string): boolean {
  let tld: string = '';
  let user: string = '';

  let totalAtSymbols: number = 0;
  for (let index = 0; index < input.length; index++) {
    const element = input[index];
    if (element == '@') {
      totalAtSymbols++;
    }
  }

  if (totalAtSymbols == 1) {
    let splitbyat = input.trim().split('@');
    if ((splitbyat.length = 2)) {
      user = splitbyat[0];

      if (user.length > 0) {
        let splitbydot = splitbyat[1].split('.');

        if (splitbydot.length == 2) {
          tld = splitbydot[splitbydot.length - 1];

          if (tld.length > 1) {
            return true;
          }
        }
      }
    }
  }

  return false;
}

export function formatPhoneInput(input: string): string {
  let allNumbersString: Array<string> = [];
  input.split('').forEach((element) => {
    if (parseInt(element) || element === '0') {
      allNumbersString.push(element);
    }
  });

  let numerString: string = '';
  if (allNumbersString.length > 0) {
    allNumbersString.forEach((element) => {
      numerString = numerString + element;
    });
  }

  if (numerString != '') {
    return numerString;
  } else {
    return '';
  }
}

export function RetrieveOAuthLink(channel: string): string {
  let redirectUri = `${GetBaseRedirectUri()}/account/login/${channel}`;
  let clientId = COMMON_SETTINGS.SocialLoginAppSettings.Google.ClientId;
  if (CheckIfProd()) {
    clientId = COMMON_SETTINGS.SocialLoginAppSettings.Google.ClientIdProd;
  }
  if (channel === 'google') {
    return `https://accounts.google.com/o/oauth2/v2/auth?scope=openid+profile+email+https://www.googleapis.com/auth/userinfo.profile&response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}&prompt=select_account&access_type=online&include_granted_scopes=true`;
  } else if (channel === 'facebook') {
    return `https://www.facebook.com/v13.0/dialog/oauth?client_id=${COMMON_SETTINGS.SocialLoginAppSettings.Facebook.ClientId}&redirect_uri=${redirectUri}&response_type=code,granted_scopes&scope=public_profile%2Cemail`;
  } else if (channel === 'twitch') {
    return `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${COMMON_SETTINGS.SocialLoginAppSettings.Twitch.ClientId}&redirect_uri=${redirectUri}&scope=user%3Aread%3Aemail+openid`;
  }
  return '';
}

export function GetBaseRedirectUri() {
  if (COMMON_SETTINGS.Hostname.Local.match(location.hostname)) {
    return COMMON_SETTINGS.SocialRedirectUriHost.Local;
  } else if (COMMON_SETTINGS.Hostname.Sandbox.match(location.hostname)) {
    return COMMON_SETTINGS.SocialRedirectUriHost.Sandbox;
  } else {
    return COMMON_SETTINGS.SocialRedirectUriHost.Production;
  }
}

export function CheckIfProd(): boolean {
  return (
    //!self.location.host.match('quick') &&
    !self.location.host.match('netlify') &&
    !self.location.host.startsWith('sandbox') &&
    !self.location.host.startsWith('local')
  );
}

export function IfProd(): string {
  if (CheckIfProd()) {
    return 'Production';
  } else return 'Sandbox';
}

export function IfSandboxNotLocal(): boolean {
  if (
    !!self.location.host.match('localhost') ||
    !!self.location.host.match('netlify') ||
    !!self.location.host.startsWith('sandbox')) {
    return true;
  } else return false;
}
